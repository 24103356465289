.App {
  text-align: center;
}

.App-header {
  background-color: #2660A4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  color: #EDF7F6;
}

.App-error {
  color: #F19953;
}

.App-word {
  font-size: calc(10px + 8vmin);
}

.App-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-size: calc(10px + 6vmin);
}